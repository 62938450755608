<template>
    <Modal v-model="isShowErrorModal">
        <template v-slot:modal-content>
            <div class="warning-container">
                <div class="message-container">
                    <div class="warning-icon"></div>
                    <div class="message">
                        {{ message }}
                    </div>
                </div>
            </div>
            <div v-if="errorDetail" class="error-detail margin">
                <div v-html="errorDetail"></div>
            </div>
        </template>
        <template v-slot:modal-bottom>
            <Button
                buttonWord="我知道了"
                buttonStyle="blue"
                @click="clickHandler()"
            />
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'

export default {
    name: 'ErrorModal',
    emits: ['update:modelValue'],
    components: {
        Modal,
        Button
    },
    methods: {
        clickHandler: function () {
            this.$store.commit('errorHandler')
            this.isShowErrorModal = false
        }
    },
    watch: {
        isShowErrorModal: function (value) {
            if (!value) {
                const body = document.querySelector('body')
                this.$removeAllClass(body, 'not-scroll')
            }
        }
    },
    computed: {
        isShowErrorModal: {
            get: function () {
                return this.$store.state.showErrorModal
            },
            set: function (value) {
                this.$store.commit('set', {
                    showErrorModal: value
                })
            }
        },
        message: function () {
            return this.$store.state.errorMessage
        },
        errorDetail: function () {
            return this.$store.state.errorDetail
        }
    }
}
</script>

<style lang="scss" scoped>
.warning-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.message-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    .warning-icon {
        width: 34px;
        height: 34px;
        margin-right: 10px;
    }

    .message {
        color: $fourth-black;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }
}

.error-detail {
    margin-top: 20px;
    font-size: 14px;
    color: $secondary-grey;
    word-break: break-all;
    white-space: pre-line;
}

:deep(.modal-background) {
    @media screen and (max-width: 576px) {
        height: 100vh;
    }
}
</style>
